import React from 'react';
import { Theme } from 'evcharging/app/themes';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import FooterLogoLink from 'evcharging/containers/layout/FooterLogoLink';
import {
  ABOUT_US_URL,
  BLOG_URL,
  CONTACT_US_URL,
  EV_CALCULATOR_URL,
  EV_CHARGER_URL,
  EV_REBATES_URL,
  EV_SOFTWARE_URL,
  HOME_URL,
  PRIVACY_POLICY_URL,
  RSS_URL,
  TERMS_AND_CONDITIONS_URL,
} from 'evcharging/app/seo/urls';
import Link from 'evcharging/components/Link';
import Spacer from 'evcharging/components/Spacer';
import facebook from 'evcharging/assets/layout/facebook.png';
import instagram from 'evcharging/assets/layout/instagram.png';
import linkedin from 'evcharging/assets/layout/linkedin.png';
import twitter from 'evcharging/assets/layout/twitter.png';
import youtube from 'evcharging/assets/layout/youtube.png';
import rss from 'evcharging/assets/layout/rss.png';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from 'evcharging/app/seo/social';
import Image from 'next/image';
import Section from 'evcharging/containers/layout/Section';
import Container from 'evcharging/components/Container';
import Box from 'evcharging/components/Box';
import { PHONE_NUMBER } from 'evcharging/app';
import useI18n from "evcharging/hooks/useI18n";

const FooterDesktop = () => {
  const { t } = useI18n();

  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <Section background={'dark'} padding={'61px 0 24px'}>
      <Container>
        <div className={styles.wrapper}>
          <Box marginRight={'auto'}>
            <FooterLogoLink href={HOME_URL} />
            <Spacer value={16} />
            <div className={styles.text}>
              {t('footer.about')}
            </div>
            <Spacer value={24} />
            <nav>
              <Link href={FACEBOOK_URL} className={styles.icon}>
                <Image src={facebook} width={10} height={20} alt={'facebook'} />
              </Link>
              <Link href={INSTAGRAM_URL} className={styles.icon}>
                <Image src={instagram} width={22} height={22} alt={'instagram'} />
              </Link>
              <Link href={LINKEDIN_URL} className={styles.icon}>
                <Image src={linkedin} width={22} height={22} alt={'linkedin'} />
              </Link>
              <Link href={TWITTER_URL} className={styles.icon}>
                <Image src={twitter} width={22} height={18} alt={'twitter'} />
              </Link>
              <Link href={YOUTUBE_URL} className={styles.icon}>
                <Image src={youtube} width={24} height={16} alt={'youtube'} />
              </Link>
              <Link href={RSS_URL} className={styles.icon} target={'_blank'}>
                <Image src={rss} width={19} height={19} alt={'rss'} />
              </Link>
            </nav>
            <a href="https://www.bbb.org/us/nj/voorhees/profile/electric-vehicle-charging-stations/energy5-0221-90222620/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-newjersey.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-90222620.png" style={{border: 0, marginTop: 20}} alt="Energy5 BBB Business Review" /></a>
          </Box>
          <div className={styles.column}>
            {/*<Link href={BLOG_URL} className={styles.page}>
              {t('nav.blog')}
            </Link>*/}
            <Link href={EV_REBATES_URL} className={styles.page}>
              {t('nav.incentives')}
            </Link>
            <Link href={EV_CALCULATOR_URL} className={styles.page}>
              {t('nav.calculator')}
            </Link>
            <Link href={EV_CHARGER_URL} className={styles.page}>
              {t('nav.hardware')}
            </Link>
            <Link href={EV_SOFTWARE_URL} className={styles.page}>
              {t('nav.software')}
            </Link>
            <Link href={ABOUT_US_URL} className={styles.page}>
              {t('nav.about')}
            </Link>
            <Link href={CONTACT_US_URL} className={styles.page}>
              {t('nav.contact')}
            </Link>
            <Link href={PRIVACY_POLICY_URL} className={styles.link}>
              {t('nav.privacy')}
            </Link>
            <Link href={TERMS_AND_CONDITIONS_URL} className={styles.link}>
              {t('nav.terms')}
            </Link>
          </div>
          <div className={styles.column}>
            <div className={styles.title}>{t('form.label.address')}</div>
            <div className={styles.link}>
              300 W Somerdale Rd, Suite 5, Voorhees Township, NJ 08043
            </div>
            <div className={styles.title}>{t('form.label.email')}</div>
            <div className={styles.link}>hello@energy5.com</div>
            <div className={styles.title}>{t('form.label.phone')}</div>
            <a href={`tel:${PHONE_NUMBER}`} className={styles.link}>
              {PHONE_NUMBER}
            </a>
          </div>
        </div>
        <Spacer value={72} mobile={24} />
        <Box maxWidth={235} margin={'0 auto'} className={styles.copyright}>
          {t('footer.powered-by')}&nbsp;
          <a href={'https://usenergysolutions.us/'} target={'_blank'} rel="noreferrer">
            US Energy Solutions
          </a>
        </Box>
      </Container>
    </Section>
  );
};

const createStyles = (theme: Theme) => ({
  wrapper: css`
    display: flex;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    margin-left: 95px;
  `,
  page: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #fff;
    margin-bottom: 16px;
    :hover {
      color: ${theme.colors.accent};
    }
  `,
  title: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #fff;
    margin-bottom: 6px;
  `,
  text: css`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(246, 248, 249, 0.7);
    max-width: 280px;
  `,
  link: css`
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    color: ${theme.colors.text.light};
    opacity: 0.6;
    margin-bottom: 16px;
    max-width: 285px;
    line-height: 150%;
    text-decoration: none;
  `,
  icon: css`
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.text.light};
    + * {
      margin-left: 30px;
    }
  `,
  copyright: css`
    color: #909090;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    a {
      color: #909090;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      :hover {
        color: ${theme.colors.accent};
      }
    }
  `,
});

export default FooterDesktop;
